import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import { Services } from 'Services';
import { Skeleton } from '@mui/material';
import moment from 'moment';

const OrderGeneral = () => {
    const { orderId } = useParams();
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getOrderDetails(orderId, token);
                setOrder(response.order); // Adjust this line based on your API response structure
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [orderId]);

    if (loading) {
        // Show skeleton loading state while fetching data
        return (
            <table className={ViewUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={ViewUserClasses['table-header']}>General</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="2">
                            <div style={{padding: '5px'}} className={ViewUserClasses['skeleton-viewuseroverview']}>
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }


    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!order) {
        return <div>No user found.</div>;
    }

    const formatDate = (dateString) => {
        return moment(dateString).format('DD MMM YYYY');
    };


    return (
        <table className={ViewUserClasses['user-table']}>
            <thead>
                <tr>
                    <th colSpan="2" className={ViewUserClasses['table-header']}>General</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Order Id</td>
                    <td className={ViewUserClasses['table-value']}>{order.orderId}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Date Ordered</td>
                    <td className={ViewUserClasses['table-value']}>{formatDate(order.createdAt)}</td>
                </tr>   
                <tr>
                    <td className={ViewUserClasses['table-label']}>Company</td>
                    <td className={ViewUserClasses['table-value']}>{order.company}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Order Value</td>
                    <td className={ViewUserClasses['table-value']}>{order.grandTotal}</td>
                </tr>
                <tr>
                    <td className={ViewUserClasses['table-label']}>Delivery Charges</td>
                    <td className={ViewUserClasses['table-value']}>{order.deliveryCharges}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default OrderGeneral;
