import AddFaq from "pages/faq/AddFaq";
import { Error_Dark } from "./Util/Toast";
const httpcall = (url, method, body, token) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
        method: method,

        body: body,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            ...token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.Status === 0) {
                if (
                    res.Message === "Invalid Token" ||
                    res.Message === "Token expired"
                ) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.href = "/";
                    Error_Dark("Token Expired");
                }
            }
            return res;
        });
};

const Login = (method, body) => {
    return httpcall("admin/adminLogin", method, body);
};

const createUser = (userData, token) => {
    return httpcall("admin/createUser", "POST", JSON.stringify(userData), token);
};

const getUsers = (status, token) => {
    return httpcall(`admin/getUsers/${status}`, "GET", null, token);
};

const getUserDetails = (userId, token) => {
    return httpcall(`admin/getUserDetails/${userId}`, "POST", null, token);
};

const updateUser = (userId, userData, token) => {
    return httpcall(`admin/updateUser/${userId}`, "PATCH", JSON.stringify(userData), token);
};

const addUserAddress = (userData, token) => {
    return httpcall(`admin/addUserAddress`, "POST", JSON.stringify(userData), token);
};

const deleteUserAddress = (addressId, token) => {
    return httpcall(`admin/deleteAddress/${addressId}`, "DELETE", null, token);
};

const makeAddressDefault = (userId, addressId, token) => {
    return httpcall(`admin/updateDefaultAddress/${userId}/${addressId}`, "PATCH", null, token);
};

const getUserOrdersByStatus = (userId, orderStatus, token) => {
    return httpcall(`admin/getUserOrders/${userId}/${orderStatus}`, "GET", null, token);
};


const getOrdersByStatus = (orderStatus, token) => {
    return httpcall(`admin/getOrdersByStatus/${orderStatus}`, "GET", null, token);
};

const getCouponsByStatus = (status, token) => {
    return httpcall(`admin/getCoupons/${status}`, "GET", null, token);
};

const getOrderDetails = (orderId, token) => {
    return httpcall(`admin/getOrderDetails/${orderId}`, "GET", null, token);
};

const updateDeliveryStatus = (orderId, deliveryStatus, token) => {
    return httpcall(`admin/updateDeliveryStatus/${orderId}`, "PATCH", JSON.stringify({ orderDeliveryStatus: deliveryStatus }), token);
};

const getAllProducts = (token,status) => {
    if(status){
        return httpcall(`admin/getAllproducts?status=${status}`, "GET", null, token);
        
    }
    else{
        return httpcall("admin/getAllproducts", "GET", null, token);

    }
};

const getProductDetails = (productId, token) => {
    return httpcall(`admin/getProductById/${productId}`, "GET", null, token);
};

const addProduct = (productData, token) => {
    return httpcall("admin/addProduct", "POST", JSON.stringify(productData), token);
};

const addOrder = (orderDate, token) => {
    return httpcall("admin/createOrder", "POST", JSON.stringify(orderDate), token);
};

const editProduct = (productId, productData, token) => {
    return httpcall(`admin/editProduct/${productId}`, "PUT", productData, token);
};

const editOrder = (orderId, orderData, token) => {
    return httpcall(`admin/editOrder/${orderId}`, "PATCH", JSON.stringify(orderData), token);
};

const deleteProduct = (productId, token) => {
    return httpcall(`admin/deleteProduct/${productId}`, "DELETE", null, token);
};

const getTypes = (token) => {
    return httpcall("admin/getTypes", "GET", null, token);
};

const addType = (typeData, token) => {
    return httpcall("admin/addType", "POST", typeData, token);
};

const updateType = (typeId, typeData, token) => {
    return httpcall(`admin/updateType/${typeId}`, "PATCH", typeData, token);
};

const deleteType = (typeId, token) => {
    return httpcall(`admin/deleteType/${typeId}`, "DELETE", null, token);
};

const getBrands = (token) => {
    return httpcall("admin/getBrands", "GET", null, token);
};

const addBrand = (brandData, token) => {
    return httpcall("admin/addBrand", "POST", brandData, token);
};

const editBrand = (brandId, brandData, token) => {
    return httpcall(`admin/editBrand/${brandId}`, "PATCH", brandData, token);
};

const deleteBrand = (brandId, token) => {
    return httpcall(`admin/deleteBrand/${brandId}`, "DELETE", null, token);
};

const getDeals = (token) => {
    return httpcall("admin/getDeals", "GET", null, token);
};

const addDeals = (dealData, token) => {
    return httpcall("admin/addDeal", "POST", dealData, token);
};

const editDeal = (productDealId, dealData, token) => {
    return httpcall(`admin/updateDeal/${productDealId}`, "PATCH", dealData, token);
};

const deleteDeal = (deleteDealId, token) => {
    return httpcall(`admin/deleteDeal/${deleteDealId}`, "DELETE", null, token);
};

const getCategories = (token) => {
    return httpcall("admin/getCategories", "GET", null, token);
};

const addCategory = (categoryData, token) => {
    return httpcall("admin/addCategory", "POST", categoryData, token);
};

const editCategory = (productCategoryId, categoryData, token) => {
    return httpcall(`admin/updateCategory/${productCategoryId}`, "PATCH", categoryData, token);
};

const deleteCategory = (deleteDealId, token) => {
    return httpcall(`admin/deleteCategory/${deleteDealId}`, "DELETE", null, token);
};

const searchProducts = (searchData, token) => {
    return httpcall("admin/searchProducts", "POST", JSON.stringify(searchData), token);
};

const getCoupons = (token) => {
    return httpcall("admin/getCoupons", "GET", null, token);
};

const createCoupon = (couponData, token) => {
    return httpcall("admin/createCoupon", "POST", couponData, token);
};

const getCouponById = (couponCodeID, token) => {
    return httpcall(`admin/getCouponById/${couponCodeID}`, "GET", null, token);
};

const updateCoupon = (couponCodeID, couponData, token) => {
    return httpcall(`admin/updateCoupon/${couponCodeID}`, "PATCH", JSON.stringify(couponData), token);
};

const getFAQs = (token) => {
    return httpcall("admin/getFaqs", "GET", null, token);
};

const getFAQById = (customerSupportId, token) => {
    return httpcall(`admin/getFaqsById/${customerSupportId}`, "GET", null, token);
};

const addFAQ = (faqData, token) => {
    return httpcall("admin/createFaq", "POST", faqData, token);
};


const updateFAQ = (customerSupportId, updatedData, token) => {
    return httpcall(`admin/updateFaqs/${customerSupportId}`, "PATCH", updatedData, token);
};

const deleteFAQ = (customerSupportId, token) => {
    return httpcall(`admin/deleteFaq/${customerSupportId}`, "DELETE", null, token);
};


const getConfig = (token) => {
    return httpcall("admin/getConfig", "GET", null, token);
};

const updateConfig = (configData, token) => {
    return httpcall("admin/updateConfig", "PATCH", configData, token);
};


const getHelp = (token) => {
    return httpcall("admin/getHelp", "GET", null, token);
};

const addHelp = (helpData, token) => {
    return httpcall("admin/addHelpQueries", "POST", helpData, token);
};

const updateHelp = (helpId, helpData, token) => {
    return httpcall(`admin/updateHelp/${helpId}`, "PATCH", helpData, token);
};

const getDashBoard = (token) => {
    return httpcall("admin/getdashBoard", "GET", null, token);
};

const resetPassword = (passwordData, token) => {
    return httpcall("admin/updatePassword", "POST", JSON.stringify(passwordData), token);
};

const verifyUserDocument = async (userId, documentId, status, rejectReason, token) => {
    const payload = {
        userId,
        status,
        rejectReason: rejectReason || '', // Ensure rejectReason is included as an empty string if not provided
    };

    try {
        const response = await httpcall(`admin/verifyDocument/${documentId}/${status}`, 'PATCH', JSON.stringify(payload), token);
        return response; // Assuming response is handled appropriately by httpcall function
    } catch (error) {
        throw error; // Propagate the error to handle it in the calling component
    }
};



const resetUserPassword = (passwordData, token) => {
    return httpcall("admin/resetUserPassword", "POST", JSON.stringify(passwordData), token);
};

const deleteUser = (selectedUserId, token) => {
    console.log(selectedUserId)
    return httpcall(`admin/deleteUser/${selectedUserId}`, "DELETE", null, token);
};

const deleteOrder = (orderId, token) => {
    return httpcall(`admin/deleteOrder/${orderId}`, "DELETE", null, token);
};

const makeProductOnlineOffline = (productId, isActive, token) => {
    return httpcall(`admin/makeProductOnlineOffline/${productId}/${isActive}`, "PATCH", null, token);
};

const getDealEndingSoon = (token) => {
    return httpcall("admin/getDealEndingSoon", "GET", null, token);
};

const createDealEndingSoon = (dealData, token) => {
    return httpcall("admin/createDealEndingSoon", "POST", JSON.stringify(dealData), token);
};

const getDealEndingSoonProducts = (dealsEndingSoonId, token) => {
    return httpcall(`admin/getDealEndingSoonProducts/${dealsEndingSoonId}`, "GET", null, token);
};

const updatePaymentStatus = (orderId, paymentStatus, token) => {
    const payload = {
        orderId: orderId,
        paymentStatus: paymentStatus,
    };
    return httpcall("admin/updatePaymentStatus", "POST", JSON.stringify(payload), token);
};

const updateOrderDeliveryStatus = (orderId, orderStatus, token) => {
    const payload = {
        orderStatus: orderStatus,
    };
    return httpcall(`admin/updateDeliveryStatus/${orderId}`, "PATCH", JSON.stringify(payload), token);
};

const acceptUserDeleteRequest = (userId, token) => {
    return httpcall(`admin/acceptUserDeleteRequest/${userId}`, "DELETE", null, token);
};

const getBanners = (token) => {
    return httpcall("admin/getBanners", "GET", null, token);
};

const addBanner = (bannerData, token) => {
    return httpcall("admin/addBanner", "POST", JSON.stringify(bannerData), token);
};

const updateBanner = (bannerId, bannerData, token) => {
    return httpcall(`admin/updateBanner/${bannerId}`, "PATCH", JSON.stringify(bannerData), token);
};

const deleteBanner = (bannerId, token) => {
    return httpcall(`admin/deleteBanner/${bannerId}`, "DELETE", null, token);
};

const deleteCoupon = (couponCodeID, token) => {
    return httpcall(`admin/deleteCoupon/${couponCodeID}`, "DELETE", null, token);
};

export const Services = {
    Login,
    createUser,
    getUsers,
    getUserDetails,
    updateUser,
    makeAddressDefault,
    addUserAddress,
    deleteUserAddress,
    getUserOrdersByStatus,
    getOrdersByStatus,
    getOrderDetails,
    editOrder,
    updateDeliveryStatus,
    getAllProducts,
    getProductDetails,
    addProduct,
    addOrder,
    editProduct,
    deleteProduct,
    getTypes,
    addType,
    updateType,
    deleteType,
    getBrands,
    addBrand,
    editBrand,
    deleteBrand,
    getDeals,
    addDeals,
    editDeal,
    deleteDeal,
    getCategories,
    addCategory, 
    editCategory,
    deleteCategory,
    searchProducts,
    getCoupons,
    createCoupon,
    getCouponById,
    updateCoupon,
    getFAQs,
    getFAQById,
    addFAQ,
    updateFAQ,
    deleteFAQ,
    getConfig,
    updateConfig,
    getHelp,
    addHelp,
    updateHelp,
    getDashBoard,
    resetPassword,
    verifyUserDocument,
    resetUserPassword,
    deleteUser,
    deleteOrder,
    makeProductOnlineOffline,
    getDealEndingSoon,
    createDealEndingSoon,
    getDealEndingSoonProducts,
    updatePaymentStatus,
    updateOrderDeliveryStatus,
    getCouponsByStatus,
    acceptUserDeleteRequest,
    getBanners,
    addBanner,
    updateBanner,
    deleteBanner,
    deleteCoupon,
};
