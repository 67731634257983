import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'Components/Layout';
import CommonClasses from 'Styles/Common.module.css';
import ProductClasses from '../Product/Products.module.css';
import BoardClasses from './Dashboard.module.css';
import { Services } from 'Services';
import { FaUserAlt } from 'react-icons/fa';
import { MdPayment } from 'react-icons/md';
import CountUp from 'react-countup';
import { VscFeedback } from 'react-icons/vsc';
import { AiOutlineDollar } from 'react-icons/ai';
import { SiMoneygram } from "react-icons/si";
import { GiMoneyStack } from "react-icons/gi";
import { GiBoxUnpacking } from "react-icons/gi";
import { TbPackageExport } from "react-icons/tb";
import { SiBrandfolder } from "react-icons/si";
import { FaIdeal } from "react-icons/fa";
import { FaBoxes } from "react-icons/fa";
import { HiMiniReceiptPercent } from "react-icons/hi2";
import { BiSolidOffer, BiZoomIn } from "react-icons/bi";
import { BsFillTagsFill } from "react-icons/bs";
import { HiArchiveBoxXMark  } from "react-icons/hi2";
import { ImCheckboxChecked } from "react-icons/im";



const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState({
        todayOrdersCount: 0,
        totalOrders: 0,
        todayIncome: 0,
        totalIncome: 0,
        totalBrands: 0,
        totalDeals: 0,
        totalProducts: 0,
        deletedOrders: 0,
        unitsSold: 0,
    });

    useEffect(() => {
        // Fetch the dashboard data when the component mounts
        const fetchDashboardData = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('token')}` };
                if (token) {
                    const response = await Services.getDashBoard(token);
                    // Check if the response is successful
                    if (response.Status === 1) {
                        // Update the state with the fetched data
                        setDashboardData({
                            todayOrdersCount: response.todayOrdersCount,
                            totalOrders: response.totalOrders,
                            todayIncome: response.todayIncome,
                            totalIncome: response.totalIncome,
                            totalBrands: response.totalBrands,
                            totalDeals: response.totalDeals,
                            totalProducts: response.totalProducts,
                            deletedOrders: response.deletedOrders,
                            unitsSold: response.unitsSold,
                        });
                    } else {
                        console.error('Error fetching dashboard data:', response.Message);
                    }
                }
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };

        fetchDashboardData();
    }, []);

    return (
        <Layout Active={"Dashboard"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={CommonClasses['wrapper']}>
                    <h3 className={ProductClasses['main-header']}>Dashboard</h3>
                    <div className={BoardClasses['users-secondary-main']}>
                        <div className={BoardClasses['sec-card-three']} data-aos="zoom-in-down">
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><SiMoneygram /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Total Income</p>
                                <p className={BoardClasses['pendingtickets-text']}>
                                    <CountUp end={dashboardData.totalIncome} duration={2} prefix="₹" decimals={2} />
                                </p>
                            </div>
                        </div>
                        <div className={BoardClasses['sec-card-two']} data-aos="zoom-in-down">
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><GiMoneyStack /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Today's Income</p>
                                <p className={BoardClasses['pendingpayment-text']}>
                                    <CountUp end={dashboardData.todayIncome} duration={2} prefix="₹" decimals={2} />
                                </p>
                            </div>
                        </div>
                        <div className={BoardClasses['sec-card-one']} data-aos="zoom-in-down">
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><TbPackageExport /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Today Orders</p>
                                <p className={BoardClasses['registration-text']}>
                                    <CountUp end={dashboardData.todayOrdersCount} duration={2} />
                                </p>
                            </div>
                        </div>
                        <div className={BoardClasses['sec-card-three']} data-aos="zoom-in-down">
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><GiBoxUnpacking /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Total Orders</p>
                                <p className={BoardClasses['pendingtickets-text']}>
                                    <CountUp end={dashboardData.totalOrders} duration={2} />
                                </p>
                            </div>
                        </div>
                        <div className={BoardClasses['sec-card-three']}>
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><SiBrandfolder /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Total Brands</p>
                                <p className={BoardClasses['pendingtickets-text']}>
                                    <CountUp end={dashboardData.totalBrands} duration={2} />
                                </p>
                            </div>
                        </div>
                        <div className={BoardClasses['sec-card-three']}>
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><BsFillTagsFill /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Total Deals</p>
                                <p className={BoardClasses['pendingtickets-text']}>
                                    <CountUp end={dashboardData.totalDeals} duration={2} />
                                </p>
                            </div>
                        </div>
                        <div className={BoardClasses['sec-card-three']}>
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><FaBoxes /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Total Products</p>
                                <p className={BoardClasses['pendingtickets-text']}>
                                    <CountUp end={dashboardData.totalProducts} duration={2} />
                                </p>
                            </div>
                        </div>
                        <div className={BoardClasses['sec-card-three']}>
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><HiArchiveBoxXMark /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Deleted Orders</p>
                                <p className={BoardClasses['pendingtickets-text']}>
                                    <CountUp end={dashboardData.deletedOrders} duration={2} />
                                </p>
                            </div>
                        </div>
                        <div className={BoardClasses['sec-card-three']}>
                            <div className={BoardClasses['inside-sec-one-card']}>
                                <h3><ImCheckboxChecked /></h3>
                            </div>
                            <div className={BoardClasses['inside-sec-one-two-card']}>
                                <p className={BoardClasses['text-sec-card']}>Units Sold</p>
                                <p className={BoardClasses['pendingtickets-text']}>
                                    <CountUp end={dashboardData.unitsSold} duration={2} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Dashboard;
