
import Categories from "pages/Categories/index"
import AddCategory from "../pages/Categories/addCategory"

import Login from "../pages/Login/index"
import Users from "pages/users/Users"
import Orders from "pages/orders/Orders"

import Product from "pages/Product/Products"
import ProductType from "pages/productconfiguration/ProductType"
import ProductDeal from "pages/productconfiguration/ProductDeal"
import ProductBrand from "pages/productconfiguration/ProductBrand"
import ProductCategory from "pages/productconfiguration/ProductCategory"
import Coupons from "pages/coupons/Coupons"
import Faq from "pages/faq/Faq"
import Config from "pages/config/Config"
import Help from "pages/help/Help"
import AccountSettings from "pages/accountsettings/AccountSettings"
import AddProduct from "pages/Product/addProducts/AddProduct"
import EditProduct from "../pages/Product/editProduct/EditProduct"
import CreateCoupon from "pages/coupons/CreateCoupon"
import EditCoupon from "pages/coupons/EditCoupon"
import EditFaq from "pages/faq/EditFaq"
import AddFaq from "pages/faq/AddFaq"
import UpdateConfig from "pages/config/UpdateConfig"
import CreateHelp from "pages/help/CreateHelp"
import UpdateHelp from "pages/help/UpdateHelp"
import Dashboard from "pages/dashboard/Dashboard"
import ViewUsers from "pages/users/viewUsers/ViewUsers"
import AddUser from "pages/users/addUser/AddUser"
import EditUser from "pages/users/editUser/EditUser"
import ViewOrder from "pages/orders/viewOrders/ViewOrder"
import AddOrder from "pages/orders/addOrder/AddOrder"
import ViewProducts from "pages/Product/viewProducts/ViewProducts"
import EditOrder from "pages/orders/editOrder/EditOrder"
import DealsEndingSoon from "pages/productconfiguration/productdealconfig/DealsEndingSoon"
import CreateDealsEndingSoon from "pages/productconfiguration/productdealconfig/CreateDealsEndingSoon"
import ViewDealEndingSoonProducts from "pages/productconfiguration/productdealconfig/ViewDealEndingSoonProducts"
import Banners from "pages/productconfiguration/productdealconfig/banners/Banners"
import CreateBanner from "pages/productconfiguration/productdealconfig/banners/CreateBanner"
import EditBanner from "pages/productconfiguration/productdealconfig/banners/EditBanner"


export const AccessKeys = {
 Categories: 'Categories',
}


export const PageRoutes = [


 { path: '/', element: <Login />, AccessKey: AccessKeys.Login, Type: 'read' },

 { path: '/categories', element: <Categories />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/categories/:category', element: <AddCategory />, AccessKey: AccessKeys.Categories, Type: 'write' },
 { path: '/users', element: <Users />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/adduser', element: <AddUser />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/view-users/:userId', element: <ViewUsers />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/edit-users/:userId', element: <EditUser />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/edit-product/:productId', element: <EditProduct />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/edit-order/:orderId', element: <EditOrder />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/orders', element: <Orders />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/addorder', element: <AddOrder />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/view-order/:orderId', element: <ViewOrder />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/products', element: <Product />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/addproduct', element: <AddProduct />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/view-products/:productId', element: <ViewProducts />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/producttype', element: <ProductType />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/productdeal', element: <ProductDeal />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/dealsendingsoon', element: <DealsEndingSoon />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/viewdealendingsoonproducts/:dealsEndingSoonId', element: <ViewDealEndingSoonProducts />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/createdealsendingsoon', element: <CreateDealsEndingSoon />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/productbrand', element: <ProductBrand />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/productcategory', element: <ProductCategory />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/coupons', element: <Coupons />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/FAQs', element: <Faq />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/config', element: <Config />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/updateconfig/:configId', element: <UpdateConfig />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/help', element: <Help />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/createhelp', element: <CreateHelp />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/updatehelp/:helpId', element: <UpdateHelp />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/accountsettings', element: <AccountSettings />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/addproducts', element: <AddProduct />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/createcoupon', element: <CreateCoupon />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/editcoupon/:couponCodeID', element: <EditCoupon />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/faq', element: <Faq />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/edit-faq/:customerSupportId', element: <EditFaq />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/addfaq', element: <AddFaq />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/dashboard', element: <Dashboard />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/banners', element: <Banners />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/createbanner', element: <CreateBanner />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/editBanner/:bannerId', element: <EditBanner />, AccessKey: AccessKeys.Login, Type: 'read' },















]