import React, { useState } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import TutorClasses from '../Categories/addCategory/index.module.css';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Services } from 'Services';
import ReactLoading from 'react-loading';
import { BsCloudUpload } from 'react-icons/bs';
import S3 from 'react-s3';
import { Success, Error } from 'Util/Toast';
import ProductClasses from '../Product/Products.module.css';
import CouponClasses from '../coupons/Coupon.module.css';
import { Breadcrumbs, Button, Link, Stack, Typography, MenuItem, Select } from '@mui/material';
import ViewUserClasses from '../users/viewUsers/ViewUsers.module.css';
import AddUserClasses from '../users/addUser/AddUser.module.css';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

window.Buffer = window.Buffer || require('buffer').Buffer;

const CreateCoupon = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [imageError, setImageError] = useState('');
    const [couponType, setCouponType] = useState('');

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const config = {
        bucketName: process.env.REACT_APP_BUCKETNAME,
        dirName: 'coupons',
        region: 'ap-south-1',
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    };

    const filehandleChange = async (event) => {
        const files = event.target.files[0];
        if (
            files.type === 'image/png' ||
            files.type === 'image/jpeg' ||
            files.type === 'image/webp'
        ) {
            try {
                const timestamp = Date.now();
                const newfile = new File(
                    [files],
                    `${timestamp}.${String(files.type).split('/')[1]}`
                );

                setLoading(true);
                const res = await S3.uploadFile(newfile, {
                    ...config,
                    dirName: 'coupons',
                });
                setImage(res.location); // Update image state with the image URL
                setImagePreview(URL.createObjectURL(files)); // Set image preview
                setValue('image', `${timestamp}.${String(files.type).split('/')[1]}`);
                setLoading(false);
                setImageError(''); // Clear the error message if image is uploaded successfully
            } catch (error) {
                console.error('Error uploading image:', error);
                setLoading(false);
                Error('Error uploading image');
            }
        } else {
            alert(`${files.name} is neither PNG nor jpeg`);
        }
    };

    const onSubmit = async (data) => {
        if (!image) {
            setImageError('Please upload an image.');
            setLoading(false);
            return;
        }
        setLoading(true);
        try {
            // Extract filename from image
            const filename = image.split('/').pop();

            // Add filename and coupon type to data
            data.image = filename;
            data.type = couponType === 'percentage' ? 1 : 2;

            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.createCoupon(JSON.stringify(data), token);
            if (response.Status === 1) {
                // Coupon added successfully
                setLoading(false);
                // Redirect to a different page or perform any other action
                navigate('/coupons');
                Success(response.Message);
            } else {
                // Handle error
                setLoading(false);
            }
        } catch (error) {
            console.error('Error adding coupon:', error);
            setLoading(false);
        }
    };

    const handleMaxDiscountChange = (event) => {
        const value = parseFloat(event.target.value);
        if (value > 95) {
            setValue('maxDiscount', 95);
        } else {
            setValue('maxDiscount', value);
        }
    };

    const fileuploadDesign = (
        <div style={{ border: 'none' }} className={TutorClasses["add-item"]} onClick={() => document.getElementById("image").click()}>
            <div className={TutorClasses['upload-image']}>
                {imagePreview && <img src={imagePreview} alt="Selected" className={ViewUserClasses['uploaded-banner-image']} />}
                {!imagePreview && (
                    <div className={TutorClasses['add-item']} style={{ border: 'none' }}>
                        <BsCloudUpload className={TutorClasses['upload-icon']} />
                        <h5>Drag Your Files here</h5>
                        <p>(Only *.jpeg, *.png will be accepted)</p>
                    </div>
                )}
                <input type="file" id="image" onChange={filehandleChange} style={{ display: 'none' }} />
            </div>
        </div>
    );

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/')}>
            Home
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={() => navigate('/coupons')}>
            Coupons
        </Link>,
        <Typography key="3" color="text.primary">
            Create Coupon
        </Typography>,
    ];

    return (
        <Layout Active={'Coupons'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Create Coupon</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={AddUserClasses['new-main-div']} style={{ paddingLeft: '20px' }}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}><MdOutlineArrowBackIos />Back</Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                </div>
                <div style={{ boxShadow: 'none' }} className={TutorClasses['wrapper']} data-aos="zoom-in-down">
                    <h2 style={{ paddingLeft: '7px' }} className={ProductClasses['main-header']}>Create Coupon</h2>
                    <form className={CouponClasses["form"]} onSubmit={handleSubmit(onSubmit)}>
                        <div className={CouponClasses['form-control']}>
                            <label htmlFor="couponCode">Coupon Code</label>
                            <input type='text' {...register('couponCode')} required />
                        </div>
                        <div className={CouponClasses['form-control']}>
                            <label htmlFor="description">Description</label>
                            <input type='text' {...register('description')} required />
                        </div>
                        <div className={CouponClasses['form-control']}>
                            <label htmlFor="type">Type</label>
                            <Select style={{ width: '290px', height: '40px' }}
                                value={couponType}
                                onChange={(e) => setCouponType(e.target.value)}
                                required
                            >
                                <MenuItem value="percentage">Percentage</MenuItem>
                                <MenuItem value="money">Money</MenuItem>
                            </Select>
                        </div>
                        <div className={CouponClasses['form-control']}>
                            <label htmlFor="offerValue">Offer Value</label>
                            <input type="number" step="0.01" {...register('offerValue')} required />
                        </div>
                        <div className={CouponClasses['form-control']}>
                            <label htmlFor="maxDiscount">Max Discount(%)</label>
                            <input
                                type="number"
                                step="0.01"
                                {...register('maxDiscount')}
                                required
                                onChange={handleMaxDiscountChange}
                            />
                        </div>
                        <div className={CouponClasses['form-control']}>
                            <label htmlFor="startDate">Start Date</label>
                            <input type="date" {...register('startDate')} required />
                        </div>
                        <div className={CouponClasses['form-control']}>
                            <label htmlFor="endDate">End Date</label>
                            <input type="date" {...register('endDate')} required />
                        </div>
                        <div className={TutorClasses['file-upload-wrapper']}>
                            <header>
                                <h3>
                                    Upload Image <span className="important">*</span>
                                </h3>
                            </header>
                            <div className={TutorClasses["upload-image"]} required>
                                {fileuploadDesign}
                                {imageError && <p style={{ color: 'red' }}>{imageError}</p>}
                            </div>
                        </div>
                        {/* Add more input fields for other properties */}
                        <button className={CouponClasses['coup-btn']} disabled={loading}>
                            {loading ? (
                                <ReactLoading color="green" type="spokes" height={30} width={30} />
                            ) : (
                                'Submit'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default CreateCoupon;
