import React from 'react';
import { MenuItem, TextField, FormHelperText } from '@mui/material';
import AddUserClasses from '../../users/addUser/AddUser.module.css';

const AddOrderGeneral = ({ userData, setUserData, errors, validate }) => {
    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserData(prevUserData => ({
            ...prevUserData,
            [name]: value
        }));

        // Remove error when user starts typing
        validate(name, value);
    };

    return (
        <div>
            <table className={AddUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={AddUserClasses['table-header']}>General</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={AddUserClasses['table-label']}>Payment Type</td>
                        <td className={AddUserClasses['table-value']}>
                            <TextField
                                required
                                id="paymentType"
                                name="paymentType"
                                select
                                value={userData.paymentType || ''}
                                onChange={handleChange}
                                variant="outlined"
                                margin="dense"
                                className={AddUserClasses['table-textfield-all']}
                                error={!!errors.paymentType}
                            >
                                <MenuItem value={1}>Cash</MenuItem>
                                <MenuItem value={2}>Online</MenuItem>
                            </TextField>
                            {errors.paymentType && (
                                <FormHelperText error>{errors.paymentType}</FormHelperText>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default AddOrderGeneral;
