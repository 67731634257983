import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Modal, TextField } from '@mui/material';
import { FaFilePdf } from 'react-icons/fa';
import S3 from 'react-s3';
import { Success, Error } from 'Util/Toast'; // Assuming you have Toast components for success and error messages
import AddUserClasses from '../addUser/AddUser.module.css';
import TutorClasses from '../../Categories/addCategory/index.module.css';
import CouponClasses from '../../coupons/Coupon.module.css';

window.Buffer = window.Buffer || require('buffer').Buffer;

const config = {
    bucketName: process.env.REACT_APP_BUCKETNAME,
    dirName: 'documents',
    region: 'ap-south-1',
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};

const AddUserDocuments = ({ userData, setUserData, checkUploadStatus }) => {
    const [open, setOpen] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [fileData, setFileData] = useState({
        file: null,
        preview: '',
        expiryDate: '',
        status: 0,
    });

    const [documentType, setDocumentType] = useState(0); // 0 means no specific type selected
    const [error, setError] = useState('');

    useEffect(() => {
        if (selectedDoc) {
            // Determine documentType based on selectedDoc
            switch (selectedDoc.name) {
                case 'Drug License':
                    setDocumentType(1);
                    break;
                case 'Aadhar Card':
                    setDocumentType(2);
                    break;
                case 'Pan Card':
                    setDocumentType(3);
                    break;
                case 'Support Documents':
                    setDocumentType(4);
                    break;
                default:
                    setDocumentType(0);
                    break;
            }

            const existingDoc = (userData.documents || []).find(doc => doc.name === selectedDoc.name);
            if (existingDoc) {
                setFileData({
                    file: null,
                    preview: existingDoc.document ? `https://${config.bucketName}.s3.${config.region}.amazonaws.com/${config.dirName}/${existingDoc.document}` : '',
                    expiryDate: existingDoc.expiryDate || '',
                    status: existingDoc.status,
                });
            } else {
                setFileData({ file: null, preview: '', expiryDate: '', status: 0 });
            }
        }
    }, [selectedDoc, userData.documents]);

    const documents = [
        { name: 'Aadhar Card', mandatory: true, uploaded: userData.documents?.some(doc => doc.name === 'Aadhar Card') },
        { name: 'Pan Card', mandatory: true, uploaded: userData.documents?.some(doc => doc.name === 'Pan Card') },
        { name: 'Drug License', mandatory: true, uploaded: userData.documents?.some(doc => doc.name === 'Drug License') },
        { name: 'Support Documents', mandatory: true, uploaded: userData.documents?.some(doc => doc.name === 'Support Documents') }
    ];

    const handleOpen = (doc) => {
        setSelectedDoc(doc);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedDoc(null);
        setFileData({ file: null, preview: '', expiryDate: '', status: 0 });
        setError(''); // Clear any previous errors when closing modal
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const timestamp = Date.now();
        const newFile = new File([file], `${timestamp}.${String(file.type).split('/')[1]}`);
        setFileData({
            ...fileData,
            file: newFile,
            preview: URL.createObjectURL(file)
        });
    };

    const handleDateChange = (event) => {
        setFileData({
            ...fileData,
            expiryDate: event.target.value
        });
    };

    const handleUpload = async () => {
        if (fileData.file && documentType !== 0) {
            try {
                const res = await S3.uploadFile(fileData.file, config);
                const endpoint = res.location.split('/').pop();
                const updatedDocuments = (userData.documents || []).filter(doc =>
                    doc.name !== selectedDoc.name
                );
                updatedDocuments.push({
                    name: selectedDoc.name,
                    document: endpoint,
                    expiryDate: fileData.expiryDate,
                    uploaded: true,
                    status: 0,  // Add status here
                    documentType: documentType // Include documentType in the document data
                });

                setUserData(prevUserData => ({
                    ...prevUserData,
                    documents: updatedDocuments
                }));

                checkUploadStatus(selectedDoc.name, true); // Update upload status
                handleClose();
                Success('File uploaded successfully');
            } catch (error) {
                console.error('Error uploading file:', error);
                Error('Failed to upload file');
            }
        } else {
            console.error('File or document type not selected.');
            setError('Please upload all documents to continue'); // Set error message for validation
        }
    };

    const handleReupload = (doc) => {
        setSelectedDoc(doc);
        setFileData({
            file: null,
            preview: '',
            expiryDate: doc.expiryDate || '',
            status: doc.status
        });
        setOpen(true);
    };

    const handleSaveChanges = () => {
        const allDocumentsUploaded = documents.every(doc => doc.uploaded);
        if (!allDocumentsUploaded) {
            setError('Please upload all mandatory documents.');
        } else {
            setError(''); // Clear any previous errors
            // Proceed with saving changes or any additional logic
        }
    };

    return (
        <div>
            <Typography color="error">{error}</Typography> {/* Display error message outside the modal */}
            {documents.map((doc) => (
                <div key={doc.name}>
                    <Box className={AddUserClasses['box-item']} display="flex" justifyContent="space-between" alignItems="center" p={2} bgcolor="background.paper" boxShadow={1} mb={2}>
                        <Typography variant="h6">{doc.name}</Typography>
                        {!doc.uploaded && (
                            <button className={AddUserClasses['add-details-overview']} variant="outlined" onClick={() => handleOpen(doc)}>+ Add details</button>
                        )}
                        {doc.uploaded && (
                            <>
                                <Typography className={AddUserClasses['reupload-text']} variant="body1">Uploaded</Typography>
                                <button className={AddUserClasses['reupload']} onClick={() => handleReupload(doc)}>Re-upload</button>
                            </>
                        )}
                    </Box>
                </div>
            ))}
            <Modal open={open} onClose={handleClose}>
                <Box className={AddUserClasses['modal-content']}>
                    <Typography variant="h6">Upload {selectedDoc?.name}</Typography>
                    <div className={TutorClasses['upload-image']}>
                        {fileData.preview && <img src={fileData.preview} alt="Selected" className={CouponClasses['uploaded-coupon-image']} />}
                        <div className={TutorClasses['add-item']} onClick={() => document.getElementById("file-input").click()}>
                            <FaFilePdf className={TutorClasses['upload-icon']} />
                            <h5>Drag & Drop</h5>
                            <p>PDF</p>
                            <p>or</p>
                            <button className={AddUserClasses['Browse-btn']}>Browse</button>
                            <p>(Only *.jpeg, *.png will be accepted)</p>
                        </div>
                        <input type="file" id="file-input" onChange={handleFileChange} style={{ display: 'none' }} />
                    </div>
                    {selectedDoc?.name === 'Drug License' || selectedDoc?.name === 'Support Documents' ? (
                        <TextField
                            label="Expiry Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={fileData.expiryDate}
                            onChange={handleDateChange}
                            className={AddUserClasses['expiry-date']}
                        />
                    ) : null}
                    <Box mt={5} display="flex" justifyContent="flex-end" className={AddUserClasses['useraddress-mod']}>
                        <button className={AddUserClasses['useraddress-save-mod']} variant="contained" color="primary" onClick={handleUpload}>OK</button>
                        <button className={AddUserClasses['useraddress-cancel-mod']} variant="outlined" color="secondary" onClick={handleClose} style={{ marginLeft: '10px' }}>Cancel</button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default AddUserDocuments;
