
import uuid from "react-uuid";

import { IoIosFolder, IoMdWallet } from 'react-icons/io'
import { FaUsers } from "react-icons/fa";
import { FaBoxes } from "react-icons/fa";
import { FaBoxOpen } from "react-icons/fa";
import { TbAlignBoxRightTopFilled } from "react-icons/tb";
import { RiCoupon3Fill } from "react-icons/ri";
import { FcFaq } from "react-icons/fc";
import { FcDataConfiguration } from "react-icons/fc";
import { MdLiveHelp } from "react-icons/md";
import { RiUserSettingsFill } from "react-icons/ri";
import { MdDashboard } from "react-icons/md";
import { FaQuestion } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";





export const menuItems = [

   {
      id: uuid(),
      multiple: false,
      name: "Dashboard",
      menuLink: "/dashboard",
      active: "Dashboard",
      icon: MdDashboard,
    
     },

 {
    id: uuid(),
    multiple: false,
    name: "Users",
    menuLink: "/users",
    active: "Users",
    icon: FaUsers,
  
   },

   {
    id: uuid(),
    multiple: false,
    name: "Orders",
    menuLink: "/orders",
    active: "Orders",
    icon: FaBoxOpen,
  
   },

   {
    id: uuid(),
    multiple: false,
    name: "Products",
    menuLink: "/products",
    active: "Products",
    icon: FaBoxes,
  
   },

   {
    id: uuid(),
    multiple: true,
    name: "Product Configuration",
    menuLink: "/productconfiguration",
    active: "Product Configuration",
    icon: TbAlignBoxRightTopFilled,
    items: [
       {
          id:'1',
          multiple: false,
          name: "Product Type",
          menuLink: "/producttype",
          active: "Product Type",
          // Permissions: permissions.Wallet.read,
       },
       {
          id:'2',
          multiple: false,
          name: "Product Brand",
          menuLink: "/productbrand",
          active: "Product Brand",
          // Permissions: permissions.Wallet.read,
       },
       {
        id:'3',
        multiple: false,
        name: "Product Deal",
        menuLink: "/productdeal",
        active: "Product Deal",
        // Permissions: permissions.Wallet.read,
     },
     {
        id:'4',
        multiple: false,
        name: "Product Category",
        menuLink: "/productcategory",
        active: "Product Category",
        // Permissions: permissions.Wallet.read,
     },
   //   {
   //    id:'5',
   //    multiple: false,
   //    name: "Deals Ending Soon",
   //    menuLink: "/dealsendingsoon",
   //    active: "Deals Ending Soon",
   //    // Permissions: permissions.Wallet.read,
   // },
   {
      id:'5',
      multiple: false,
      name: "Banners",
      menuLink: "/banners",
      active: "Banners",
      // Permissions: permissions.Wallet.read,
   },
    ]
 },

 {
    id: uuid(),
    multiple: false,
    name: "Coupons",
    menuLink: "/coupons",
    active: "Coupons",
    icon: RiCoupon3Fill,
  
   },
  
   {
    id: uuid(),
    multiple: false,
    name: "FAQs",
    menuLink: "/faqs",
    active: "FAQs",
    icon: FaQuestion,
  
   },

   {
    id: uuid(),
    multiple: false,
    name: "Config",
    menuLink: "/config",
    active: "Config",
    icon: IoMdSettings,
  
   },

   {
    id: uuid(),
    multiple: false,
    name: "Help",
    menuLink: "/help",
    active: "Help",
    icon: MdLiveHelp,
  
   },
  
   {
    id: uuid(),
    multiple: true,
    name: "Admin Settings",
    menuLink: "/adminsettings",
    active: "Admin Settings",
    icon: RiUserSettingsFill,
    items: [
       {
          id:'1',
          multiple: false,
          name: "Account Settings",
          menuLink: "/accountsettings",
          active: "Account Settings",
          // Permissions: permissions.Wallet.read,
       },
    ]
 },
  


];

