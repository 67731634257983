import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Layout from 'Components/Layout';
import CommonClasses from 'Styles/Common.module.css';
import ProductClasses from '../Product/Products.module.css';
import Orderclasses from '../orders/Orders.module.css';
import { Services } from 'Services';
import { Tab, Tabs, Paper, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, Select, MenuItem, InputBase } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { BiPlus } from 'react-icons/bi';
import SkeletonLoad from 'Components/TableLoad/load';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Success } from 'Util/Toast';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import ViewUserClasses from '../users/viewUsers/ViewUsers.module.css';

import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import { CalendarIcon } from '@mui/x-date-pickers';

const Orders = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [paymentStatus, setPaymentStatus] = useState({});
    const [orderStatus, setOrderStatus] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        getOrdersByStatus(activeTab);
    }, [activeTab]);

    useEffect(() => {
        filterOrders(searchQuery, fromDate, toDate);
    }, [searchQuery, orders, fromDate, toDate]);

    const getOrdersByStatus = (status) => {
        let orderStatusValue = 0;
        switch (status) {
            case 1:
                orderStatusValue = 1; // Processing
                break;
            case 2:
                orderStatusValue = 2; // Shipped
                break;
            case 3:
                orderStatusValue = 3; // Delivered
                break;
            case 4:
                orderStatusValue = 4; // Cancelled
                break;
            default:
                orderStatusValue = 0; // All Orders
                break;
        }

        const token = { Authorization: `token ${localStorage.getItem('token')}` };
        Services.getOrdersByStatus(orderStatusValue, token)
            .then((response) => {
                const ordersWithIds = response.orders.map(order => ({ ...order, id: order.orderId }));
                setOrders(ordersWithIds);
                setFilteredOrders(ordersWithIds);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    const handleRowClick = (params) => {
        navigate(`/view-order/${params.row.orderId}`, { state: { activeTab } });
    };

    const handleEditOrder = (event, orderId) => {
        event.stopPropagation();
        navigate(`/edit-order/${selectedOrderId}`);
    };

    const handleMenuOpen = (event, orderId) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
        setSelectedOrderId(orderId);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleOpenDeleteDialog = () => {
        setDeleteDialogOpen(true);
        handleMenuClose();
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };

    const handleDeleteOrder = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            await Services.deleteOrder(selectedOrderId, token);
            setOrders(orders.filter(order => order.id !== selectedOrderId));
            setFilteredOrders(filteredOrders.filter(order => order.id !== selectedOrderId));
            handleCloseDeleteDialog();
            Success('Order Deleted Successfully');
        } catch (error) {
            console.error('Error deleting order:', error);
        }
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedOrders(newSelection);
    };

    const handlePaymentStatusChange = async (event, orderId) => {
        const newPaymentStatus = event.target.value;
        setPaymentStatus({ ...paymentStatus, [orderId]: newPaymentStatus });

        const token = { Authorization: `token ${localStorage.getItem('token')}` };
        try {
            await Services.updatePaymentStatus(orderId, newPaymentStatus, token);
            Success('Payment status updated successfully');
        } catch (error) {
            console.error('Error updating payment status:', error);
        }
    };

    const handleOrderStatusChange = async (event, orderId) => {
        const newStatus = event.target.value;
        setOrderStatus({ ...orderStatus, [orderId]: newStatus });

        const token = { Authorization: `token ${localStorage.getItem('token')}` };
        try {
            await Services.updateOrderDeliveryStatus(orderId, newStatus, token);
            Success('Order status updated successfully');
        } catch (error) {
            console.error('Error updating order status:', error);
        }
    };

    const statusLabels = {
        1: 'Processing',
        2: 'Shipped',
        3: 'Delivered',
        4: 'Cancelled',
    };

    const statusColors = {
        1: '#f3f3f3', // Processing - Light Blue
        2: '#feffb9', // Shipped - Blue
        3: '#79f4a3', // Delivered - Green
        4: '#ffa7a7', // Cancelled - Red
    };

    const formatDate = (dateString) => {
        return moment(dateString).format('DD MMM YYYY');
    };

    const formatOrderValue = (params) => {
        return `₹${params.value}`;
    };

    const filterOrders = (query, fromDate, toDate) => {
        const lowerCaseQuery = query.toLowerCase();
        let filtered = orders.filter(order =>
            order.orderId.toString().toLowerCase().includes(lowerCaseQuery) ||
            order.userName.toLowerCase().includes(lowerCaseQuery)
        );

        if (fromDate && toDate) {
            filtered = filtered.filter(order =>
                moment(order.createdAt).isSameOrAfter(fromDate, 'day') &&
                moment(order.createdAt).isSameOrBefore(toDate, 'day')
            );
        }

        setFilteredOrders(filtered);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };

    const columns = [
        { field: 'orderId', headerName: 'Order Id', width: 200 },
        { field: 'createdAt', headerName: 'Date Ordered', width: 200, valueFormatter: (params) => formatDate(params) },
        { field: 'userName', headerName: 'Customer Name', width: 200 },
        { field: 'grandTotal', headerName: 'Order Value', width: 150, renderCell: (params) => formatOrderValue(params) },
        {
            field: 'orderStatus',
            headerName: 'Order Status',
            width: 220,
            renderCell: (params) => {
                const isDisabled = params.row.orderStatus === 3 || params.row.orderStatus === 4; // Disable for Delivered or Cancelled
                return (
                    <Select
                        value={orderStatus[params.row.orderId] || params.value}
                        onChange={(event) => handleOrderStatusChange(event, params.row.orderId)}
                        style={{
                            width: '175px',
                            height: '30px',
                            backgroundColor: statusColors[orderStatus[params.row.orderId] || params.value],
                            color: 'black',
                        }}
                        disabled={isDisabled} // Disable the Select if the order status is Cancelled or Delivered
                    >
                        <MenuItem value={1}>Processing</MenuItem>
                        <MenuItem value={2}>Shipped</MenuItem>
                        <MenuItem value={3}>Delivered</MenuItem>
                        <MenuItem value={4}>Cancelled</MenuItem>
                    </Select>
                );
            },
        },
        
        {
            field: 'paymentStatus',
            headerName: 'Payment Status',
            width: 250,
            renderCell: (params) => (
                <Select
                    value={paymentStatus[params.row.orderId] || params.value}
                    onChange={(event) => handlePaymentStatusChange(event, params.row.orderId)}
                    style={{ width: '220px', height: '30px', backgroundColor: 'white', border: 'none' }}
                >
                    <MenuItem value={2}>Paid</MenuItem>
                    <MenuItem value={1}>Unpaid</MenuItem>
                </Select>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 130,
            renderCell: (params) => (
                <div>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleMenuOpen(event, params.row.orderId)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                        sx={{ boxShadow: 'none' }}
                    >
                        <MenuItem onClick={(event) => handleEditOrder(event, params.row.orderId)}>Edit</MenuItem>
                        <MenuItem onClick={handleOpenDeleteDialog}>Delete</MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    return (
        <Layout Active={"Orders"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Orders</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={ViewUserClasses['search-new-btn']}>
                    <div>
                        <h3 className={ProductClasses['main-header']}>Orders<span className={ProductClasses['count-of-users']}>({orders.length})</span></h3>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', width: '400px' }}>
                        <SearchIcon className={ViewUserClasses['searchicon']} />
                        <InputBase
                            placeholder="Order ID/Customer Name"
                            inputProps={{ 'aria-label': 'search orders' }}
                            style={{
                                marginLeft: '10px',
                                flex: 1,
                                padding: '5px 10px',
                                border: '1px solid #ddd',
                                borderRadius: '4px',
                                textIndent: '60px'
                            }}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                </div>
                <div className={CommonClasses['control-btns']}>
                    <Button style={{ marginBottom: '20px' }}
                        className={CommonClasses['button-for-add']}
                        onClick={() => {
                            navigate("/addorder");
                        }}>
                        <BiPlus size={20} /> Add Order
                    </Button>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', gap: '20px' }}>
                        <div className={ViewUserClasses['start-date-head']}>
                            <label className={ViewUserClasses['inside-label']} htmlFor="fromDate">Start Date</label>
                            <DatePicker
                                onChange={handleFromDateChange}
                                value={fromDate}
                                format="dd-MM-yyyy"
                                clearIcon={null}
                                calendarIcon={<CalendarIcon />}
                                dayPlaceholder='DD'
                                monthPlaceholder='MM'
                                yearPlaceholder='YY'
                                id="fromDate"
                            />
                        </div>
                        <div className={ViewUserClasses['start-date-head']}>
                            <label className={ViewUserClasses['inside-label']} htmlFor="toDate">End Date</label>
                            <DatePicker
                                onChange={handleToDateChange}
                                value={toDate}
                                format="dd-MM-yyyy"
                                clearIcon={null}
                                calendarIcon={<CalendarIcon />}
                                dayPlaceholder='DD'
                                monthPlaceholder='MM'
                                yearPlaceholder='YY'
                                id="toDate"
                            />
                        </div>
                    </div>
                </div>
                <Paper square className={Orderclasses['paper-square']}>
                    <Tabs
                        value={activeTab}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                        onChange={(event, newValue) => setActiveTab(newValue)}
                    >
                        <Tab label="All Orders" />
                        <Tab label="Processing" />
                        <Tab label="Shipped" />
                        <Tab label="Delivered" />
                        <Tab label="Cancelled" />
                    </Tabs>
                </Paper>
                {loading ? (
                    <SkeletonLoad
                        TableHead={["Order ID", "Date Ordered", "Order Value", "Order Status"]}
                    />
                ) : (
                    <div className={CommonClasses['Table']} style={{ maxHeight: 600 }}>
                        <DataGrid
                            rows={filteredOrders}
                            columns={columns}
                            pageSize={rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50, { value: -1, label: 'All' }]}
                            pagination
                            onPageChange={(newPage) => setRowsPerPage(newPage)}
                            onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
                            onRowClick={handleRowClick}
                            checkboxSelection
                            onSelectionModelChange={handleSelectionModelChange}
                            selectionModel={selectedOrders}
                        />
                    </div>
                )}
            </div>
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Order"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this order?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteOrder} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
};

export default Orders;
