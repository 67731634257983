import Layout from 'Components/Layout';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Services } from 'Services';
import SkeletonLoad from 'Components/TableLoad/load';
import CommonClasses from 'Styles/Common.module.css';
import ProductClasses from '../Product/Products.module.css';
import { BiPlus } from 'react-icons/bi';

const Config = () => {
    const navigate = useNavigate();
    const [config, setConfig] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(10);
    const [selectedConfigs, setSelectedConfigs] = useState([]);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [selectedConfigId, setSelectedConfigId] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('token')}` };
                const response = await Services.getConfig(token);
                setConfig(response.config);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching config:', error);
                setLoading(false);
            }
        };

        fetchConfig();
    }, []);

    const handleMenuOpen = (event, configId) => {
        event.stopPropagation(); // Prevent the row click event from firing
        setMenuAnchorEl(event.currentTarget);
        setSelectedConfigId(configId);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setSelectedConfigId(null);
    };

    const handleEditConfig = () => {
        navigate(`/updateconfig/${selectedConfigId}`);
        handleMenuClose();
    };

    const handleOpenDeleteDialog = () => {
        setDeleteDialogOpen(true);
        handleMenuClose();
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setSelectedConfigId(null);
    };

    const handleDeleteConfig = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            await Services.deleteConfig(selectedConfigId, token);
            setConfig(config.filter(item => item.configId !== selectedConfigId));
            handleCloseDeleteDialog();
        } catch (error) {
            console.error('Error deleting config:', error);
        }
    };

    const columns = [
        { field: 'configKey', headerName: 'Config Key', width: 400 },
        { field: 'configValue', headerName: 'Config Value', width: 400 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <div>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleMenuOpen(event, params.row.configId)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                        sx={{ boxShadow: 'none' }}
                    >
                        <MenuItem onClick={handleEditConfig}>Edit</MenuItem>
                        <MenuItem onClick={handleOpenDeleteDialog}>Delete</MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    const handleSelectionModelChange = (newSelection) => {
        setSelectedConfigs(newSelection);
    };

    // const handleRowClick = (params) => {
    //     navigate(`/updateconfig/${params.row.configId}`);
    // };

    return (
        <Layout Active={'Config'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Config</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <h3 className={ProductClasses['main-header']}>Config<span className={ProductClasses['count-of-users']}>({config.length})</span></h3>
                
                {loading ? (
                    <SkeletonLoad
                        TableHead={["CONFIG KEY", "CONFIG VALUE"]}
                    />
                ) : config.length > 0 ? (
                    <div className={CommonClasses['Table']} style={{ maxHeight: 600 }}>
                        <DataGrid
                            rows={config}
                            columns={columns}
                            pageSize={pageSize}
                            rowsPerPageOptions={[10, 25, 50, { value: -1, label: 'All' }]}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            pagination
                            getRowId={(row) => row.configId}
                            checkboxSelection
                            onSelectionModelChange={handleSelectionModelChange}
                            // onRowClick={handleRowClick}
                        />
                        {selectedConfigs.length > 0 && (
                            <div>
                                <p>Selected Configs: {selectedConfigs.join(', ')}</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <p>No Config Found</p>
                    </div>
                )}

                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delete Config"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this config?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteConfig} color="primary" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Layout>
    );
};

export default Config;
