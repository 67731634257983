import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import { Services } from 'Services';
import { FaFilePdf, FaPen } from 'react-icons/fa';
import S3 from 'react-s3';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import TutorClasses from '../../Categories/addCategory/index.module.css';
import CouponClasses from '../../coupons/Coupon.module.css';
import { Success, Error } from 'Util/Toast';
import { MdDelete } from 'react-icons/md';
import EditProdClasses from '../../Product/editProduct/EditProduct.module.css';
import { Button, Typography, Box, Modal, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import EditUserClasses from './EditUser.module.css';
import Loader, { Hourglass } from 'react-loader-spinner';
import EditOrderGeneralClasses from '../../orders/editOrder/EditOrder.module.css'

window.Buffer = window.Buffer || require('buffer').Buffer;

const config = {
    bucketName: process.env.REACT_APP_BUCKETNAME,
    dirName: 'documents',
    region: 'ap-south-1',
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};

const EditUserDocuments = () => {
    const { userId } = useParams();
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [fileData, setFileData] = useState([]);
    const [uploadedImageEndpoints, setUploadedImageEndpoints] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [expiryDate, setExpiryDate] = useState('');
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('');
    const [rejectionReason, setRejectionReason] = useState('');
    const [reasonModalIsOpen, setReasonModalIsOpen] = useState(false);

    useEffect(() => {
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        const fetchUserData = async () => {
            try {
                await delay(2000);
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getUserDetails(userId, token);
                if (response.Status === 1) {
                    const updatedUser = {
                        ...response.user,
                        documents: response.user.documents.map(doc => ({
                            ...doc,
                            status: doc.status === 1 ? 'verified' : doc.status === 2 ? 'rejected' : ''
                        }))
                    };
                    setUser(updatedUser);
                } else {
                    setError(response.Message || 'Failed to fetch user details');
                }
            } catch (error) {
                console.error('Error fetching user data', error);
                setError('Error fetching user data');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [userId]);

    const handleFileChange = async (event) => {
        const files = Array.from(event.target.files);
        const filePreviews = files.map(file => ({
            file,
            preview: URL.createObjectURL(file),
        }));

        setFileData(filePreviews);

        files.forEach(async (file) => {
            const timestamp = Date.now();
            const newFile = new File([file], `${timestamp}.${String(file.type).split('/')[1]}`);

            try {
                const res = await S3.uploadFile(newFile, config);
                const imageUrl = res.location;
                const endpoint = `${imageUrl.split('/').pop()}`;
                setUploadedImageEndpoints(prevEndpoints => [...prevEndpoints, endpoint]);
                Success('File uploaded successfully');
            } catch (uploadError) {
                Error('Failed to upload file');
            }
        });
    };

    const handleDeleteImage = (endpoint) => {
        setUploadedImageEndpoints(prevEndpoints => prevEndpoints.filter(imgEndpoint => imgEndpoint !== endpoint));
    };

    const openModal = (doc) => {
        setSelectedDoc(doc);
        setFileData([]);
        setExpiryDate(doc.expiryDate || '');
        setStatus(doc.status || '');
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedDoc(null);
        setStatus('');
    };

    const handleSaveChanges = async () => {
        if (!selectedDoc) return;

        const updatedDoc = {
            ...selectedDoc,
            expiryDate,
            document: uploadedImageEndpoints[0] || selectedDoc.document,
        };

        try {
            const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
            const response = await Services.updateUser(userId, updatedDoc, token);
            if (response.Status === 1) {
                setUser(prevUser => {
                    const updatedDocuments = prevUser.documents.map(doc =>
                        doc.name === updatedDoc.name ? updatedDoc : doc
                    );
                    return { ...prevUser, documents: updatedDocuments };
                });
                Success('Document updated successfully');
                closeModal();
            } else {
                Error('Failed to update document');
            }
        } catch (error) {
            console.error('Error updating document', error);
            Error('Failed to update document');
        }
    };

    const handleStatusChange = async (event, doc) => {
        const newStatus = event.target.value;
        setStatus(newStatus);
        setSelectedDoc(doc);

        if (newStatus === 'verified') {
            await verifyDocument(doc.documentId, 1);
        } else if (newStatus === 'rejected') {
            setReasonModalIsOpen(true);
        }
    };

    const verifyDocument = async (documentId, status, rejectReason = '') => {
        try {
            const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
            const response = await Services.verifyUserDocument(userId, documentId, status, rejectReason, token);
            if (response.Status === 1) {
                Success('Document status updated successfully');
                const updatedDocuments = user.documents.map(doc =>
                    doc.documentId === documentId ? { ...doc, status: status === 1 ? 'verified' : 'rejected' } : doc
                );
                setUser({ ...user, documents: updatedDocuments });
            } else {
                Error('Failed to update document status');
            }
        } catch (error) {
            console.error('Error updating document status', error);
            Error('Failed to update document status');
        }
    };

    const handleRejectionReasonSubmit = async () => {
        await verifyDocument(selectedDoc.documentId, 2, rejectionReason);
        setReasonModalIsOpen(false);
        setRejectionReason('');
    };

    if (loading) {
        return (
            <div className={EditOrderGeneralClasses['spinner-container']}>
                <Hourglass
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="hourglass-loading"
                    colors={['#23232A', '#60E19B']}
                />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <table className={ViewUserClasses['docs-user-table']}>
                <thead>
                    <tr>
                        <th className={ViewUserClasses['docs-table-header']}>Name</th>
                        <th className={ViewUserClasses['docs-table-header']}>Expiry Date</th>
                        <th className={ViewUserClasses['docs-table-header']}>Status</th>
                        <th className={ViewUserClasses['docs-table-header']}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {user.documents && user.documents.map((doc) => (
                        <tr key={doc.documentId || doc.name}>
                            <td className={ViewUserClasses['docs-table-value']}>
                                <div className={ViewUserClasses['edit-near-docname']}>
                                    {doc.name || "N/A"}
                                    <FaPen
                                        className={EditUserClasses['edit-pen-icon']}
                                        onClick={() => openModal(doc)}
                                    />
                                </div>
                            </td>
                            <td className={ViewUserClasses['docs-table-value']}>{doc.expiryDate || "N/A"}</td>
                            <td className={ViewUserClasses['docs-table-value']}>
                                <FormControl fullWidth>
                                    <Select
                                        value={doc.status || ''}
                                        onChange={(event) => handleStatusChange(event, doc)}
                                        style={{ color: doc.status === 'verified' ? 'green' : doc.status === 'rejected' ? 'red' : 'inherit' }}
                                    >
                                        <MenuItem value="verified">Verified</MenuItem>
                                        <MenuItem value="rejected">Rejected</MenuItem>
                                    </Select>
                                </FormControl>
                            </td>
                            <td className={ViewUserClasses['docs-table-value']}>
                                <a href={doc.document} target="_blank" rel="noopener noreferrer">View</a> | <a href={doc.document} target="_blank" rel="noopener noreferrer">Download</a> | <button className={EditUserClasses['del-btn-docs']} onClick={() => handleDeleteImage(doc.document)}><MdDelete /></button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Modal open={modalIsOpen} onClose={closeModal}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                    <Typography variant="h6" component="h2">
                        Edit Document
                    </Typography>
                    <div className={TutorClasses['upload-image']}>
                        <div className={TutorClasses['add-item']} onClick={() => document.getElementById("file-input").click()}>
                            {fileData.length > 0 ? (
                                fileData.map((file, index) => (
                                    <img
                                        key={index}
                                        src={file.preview}
                                        alt={`Selected ${index}`}
                                        className={CouponClasses['uploaded-coupon-image']}
                                    />
                                ))
                            ) : (
                                <>
                                    <FaFilePdf className={TutorClasses['upload-icon']} />
                                    <Typography>Drag & Drop</Typography>
                                    <Typography>PDF</Typography>
                                    <Typography>or</Typography>
                                    <button className={AddUserClasses['Browse-btn']}>
                                        Browse
                                        <input
                                            type="file"
                                            id="file-input"
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                            multiple
                                        />
                                    </button>
                                    <Typography>(Only *.jpeg, *.png will be accepted)</Typography>
                                </>
                            )}
                        </div>
                    </div>

                    <TextField
                        label="Expiry Date"
                        type="date"
                        value={expiryDate}
                        onChange={(e) => setExpiryDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        margin="normal"
                    />

                    <div className={EditUserClasses['modal-actions']}>
                        <button onClick={handleSaveChanges} className={EditUserClasses['save-btn-mod']}>
                            Save Changes
                        </button>
                        <button onClick={closeModal} className={EditUserClasses['close-btn-mod']}>
                            Close
                        </button>
                    </div>
                </Box>
            </Modal>

            <Modal open={reasonModalIsOpen} onClose={() => setReasonModalIsOpen(false)}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                    <Typography variant="h6" component="h2">
                        Rejection Reason
                    </Typography>
                    <TextField
                        label="Reason"
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <div className={EditUserClasses['modal-actions']}>
                        <button onClick={handleRejectionReasonSubmit} className={EditUserClasses['save-btn-mod']}>
                            Submit
                        </button>
                        <button onClick={() => setReasonModalIsOpen(false)} className={EditUserClasses['close-btn-mod']}>
                            Close
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default EditUserDocuments;
